<template>
  <b-link
    class="navbar-brand d-inline-flex"
    to="/home"
  >
    <span class="brand-logo">
      <b-img
        :src="logo"
        :alt="companyName"
        :title="companyName"
        :style="style"
      />
    </span>
    <h2
      v-if="companyName"
      class="brand-text"
    >
      {{ companyName }}
    </h2>
  </b-link>
</template>
<script>
import { $themeConfig } from '@themeConfig'
import { BImg, BLink } from "bootstrap-vue"
import { watch, ref, computed } from "@vue/composition-api"
import _ from "lodash"
import useAuthentication from "@/views/pages/authentication/useAuthentication"

export default {
  components: {
    BImg, BLink,
  },
  props: {
    size: String,
  },
  data() {
    return {
    }
  },
  computed: {
    logo() {
      if (this.$store.state.auth.company && !_.isNull(this.$store.state.auth.company.logo)) return this.$store.state.auth.company.logo
      return this.appLogoImage
    },
    companyName() {
      return (this.$store.state.auth.company) ? this.$store.state.auth.company.company : ''
    },
    style() {
      return (this.size) ? { maxWidth: this.size } : {  }
    },
  },
  setup() {
    // App Name

    const { appName, appLogoImage } = $themeConfig.app

    const {
      currentCompany,
      currentSlug,
    } = useAuthentication()

    const val2 = ref(computed(() => currentSlug.value))

    watch(val2, (newCurrentSlug, prevCurrentSlug) => {
      // console.log(['ha cambiado current slug', newCurrentSlug, prevCurrentSlug])
    })

    return {
      appName,
      appLogoImage,

      currentCompany,
      currentSlug,
      val2,
    }
  },
}
</script>

<style lang="scss">
.brand-logo{
  display: block;
  position: relative;
}
</style>
