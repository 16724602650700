// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted, reactive } from '@vue/composition-api'
import store from '@/store'

export default function useAuthentication() {
  // Use toast
  const toast = useToast()

  const currentCompany = reactive({})
  const currentSlug = ref(null)

  // Get Current Company & Slug
  currentCompany.value = store.state.auth.company
  currentSlug.value = computed(() => currentCompany.value.slug)

  return {
    // data
    currentCompany,
    currentSlug,

    // actions

  }
}
